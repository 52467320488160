.articleContent div {
  padding: 0 0 0 0 !important;
  display: inline-block;
  position: relative;
}

.articleContent .iframe-wrapper {
  padding: 0 0 0 0 !important;
}

.articleContent .canvas-video-iframe {
  height: 100%;
}

.articleContent blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.articleContent h1,
.articleContent h2,
.articleContent h3,
.articleContent h4,
.articleContent h5,
.articleContent h6,
.articleContent p,
.articleContent div {
  color: white;
}

.articleContent button {
  display: none;
}

.articleContent img {
  display: inline;
  width: 100%;
}
