.g-recaptcha > div > div {
  display: inline-block;
}

/*image max height for card class images */
.card > img {
  display: block;
  margin: 0 auto;
  max-height: 10vh;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.App {
  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inheritBackground {
  background-color: inherit;
}

/*font awesome color style*/
/*for font awesome icons*/
.fab,
.fas {
  color: #f7882f;
}

/* see https://github.com/mapbox/mapbox-gl-js/issues/3265 */
.mapboxgl-canvas {
  left: 0;
}
