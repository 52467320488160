.nav-bar {
  position: fixed;
  display: block;
  background: transparent;
  box-shadow: none;
  z-index: 9999;
}

.logo {
  display: block;
  width: 50px;
  height: 50px;
  margin: 1em;
}
