@media (min-width: 979px) {
  ul.nav li.dropdown:hover > div.dropdown-menu {
    display: block;
  }
}

.navbar-brand > img {
  width: 50px;
  height: 37px;
}
